import { IRenderTemplate } from ".";

import HighlightText from "~/components/atoms/HighlightText";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoVariant } from "~/utilities/enums/Typo";

export interface LocationOptionTemplateProps extends IRenderTemplate {
  className?: string;
}

export default function LocationOptionTemplate({
  option,
  searchString,
  className,
}: LocationOptionTemplateProps) {
  return (
    <div
      className={cn(
        "locationOption grid w-full flex-1 grid-cols-2 gap-2 lg:grid-cols-1",
        className
      )}
    >
      <HighlightText
        tag="h3"
        textHighlight={searchString}
        color={ETypoColor.TEXT}
        variant={ETypoVariant.HEADER_18}
        className=" truncate font-bold lg:text-header-18"
      >
        {option.label}
      </HighlightText>

      <Typo
        tag="p"
        color={ETypoColor.TEXT}
        variant={ETypoVariant.BODY_TITLE_16}
        className="truncate opacity-75"
      >
        {option?.locationName}
      </Typo>
    </div>
  );
}
