import {
  useLocation,
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
} from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useRemixForm } from "remix-hook-form";

import SearchBar from "../SearchBar";
import { LocationOptionTemplate } from "../SearchBar/OptionTemplates";

import Button from "~/components/atoms/Button";
import { InputRange } from "~/components/atoms/InputRange";
import { SingleSelect } from "~/components/atoms/SelectBox";
import Typo from "~/components/atoms/Typo";
import { Form } from "~/components/templates/form";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { OPTION_ANY, OPTIONS_TENURE } from "~/utilities/constants/common";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { convertUnitTypeOptions } from "~/utilities/helpers/unitType";
import {
  filterBarSchema,
  TFilterBarFormData,
} from "~/utilities/schema/filter-bar";

export enum EFilterBarVariant {
  DEFAULT = "default",
  STICKY = "sticky",
  MOBILE = "mobile",
}

export interface FilterBarProps {
  variant?: EFilterBarVariant;
  className?: string;
  classNameSticky?: string;
}

const FilterBar: React.FC<FilterBarProps> = ({
  variant = EFilterBarVariant.DEFAULT,
  className = "",
  classNameSticky = "",
}) => {
  const { t } = useTranslation();
  const handleFormInit = async (
    _methods: ReturnType<typeof useRemixForm<TFilterBarFormData>>
  ) => {};
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { filterOptions } = useRouteLoaderData("root") as IRouteLoaderData;

  const unitTypeOptions = filterOptions.unitTypes;
  const locationOptions = filterOptions.locations;
  const topOptions = filterOptions.tops;
  const priceOptions = filterOptions?.prices;

  const defaultValues: Partial<TFilterBarFormData> = {
    s: searchParams.get("s") || "",
    type: searchParams.get("type") || "",
    tenure: searchParams.get("tenure") || "",
    rangeMin: searchParams.get("rangeMin") || "",
    rangeMax: searchParams.get("rangeMax") || "",
    top: searchParams.get("top") || "",
  };

  const navigate = useNavigate();

  return (
    <Form<TFilterBarFormData>
      validationSchema={filterBarSchema}
      onMethodInit={handleFormInit}
      useFormProps={{
        mode: "onChange",
        defaultValues,
      }}
      className={cn("filter-bar-form block lg:hidden", className)}
      action={Slug.PROJECTS + location.search}
    >
      {({ register, setValue, formState: { errors }, watch }) =>
        variant === EFilterBarVariant.DEFAULT ? (
          <div className="flex flex-col gap-3">
            <SearchBar
              inputProps={{
                placeholder: t("search_project_placeholder"),
                ...register("s"),
                value: watch("s"),
              }}
              enableAutocomplete
              asyncAutocomplete
              onSelectedChange={(option) => {
                navigate(`/projects/${option?.projectSlug}`);
              }}
              renderOptionTemplate={({ option, searchString }) => (
                <LocationOptionTemplate
                  searchString={searchString}
                  option={option}
                />
              )}
            />

            <div className="flex gap-x-7 rounded-3xl bg-filterBarBackground px-7 py-6 lg:flex-col lg:rounded-xl lg:px-4 lg:py-3">
              <div className="grid flex-1 grid-cols-7 gap-x-4 gap-y-3 xxl:gap-x-5 lg:gap-y-4.5">
                <div className="col-span-2 lg:col-span-6">
                  <SingleSelect
                    placeholder={t("choose_an_address")}
                    error={errors.location?.message}
                    defaultValue={[
                      OPTION_ANY,
                      ...(locationOptions || []),
                    ]?.find((l) => l.id === searchParams.get("location") || "")}
                    data={locationOptions}
                    name="location"
                    title={t("location")}
                    dropdownClass="w-[calc(var(--button-width)+85px)]"
                    placeholderClass="truncate"
                  />
                </div>
                <div className="lg:col-span-3">
                  <SingleSelect
                    placeholder={t("any")}
                    error={errors.type?.message}
                    data={convertUnitTypeOptions(unitTypeOptions)}
                    name="type"
                    title={t("type")}
                    defaultValue={[
                      OPTION_ANY,
                      ...convertUnitTypeOptions(unitTypeOptions || []),
                    ]?.find((l) => l.id === searchParams.get("type") || "")}
                  />
                </div>
                <div className="lg:col-span-3">
                  <SingleSelect
                    placeholder={t("any")}
                    error={errors.tenure?.message}
                    data={OPTIONS_TENURE}
                    title={t("tenure")}
                    name="tenure"
                    defaultValue={[OPTION_ANY, ...OPTIONS_TENURE]?.find(
                      (l) => l.id === searchParams.get("tenure") || ""
                    )}
                  />
                </div>
                <div className="lg:col-span-3">
                  <SingleSelect
                    placeholder={t("any")}
                    error={errors.top?.message}
                    data={topOptions}
                    title={t("T.O.P")}
                    name="top"
                    defaultValue={[OPTION_ANY, ...(topOptions || [])]?.find(
                      (l) => l.id === searchParams.get("top") || ""
                    )}
                  />
                </div>
                <div className="col-span-2 lg:col-span-6">
                  <InputRange
                    errors={{
                      maxErrors: errors.rangeMax?.message,
                      minErrors: errors.rangeMin?.message,
                    }}
                    minValueSelect={{
                      placeholder: t("any"),
                      error: errors.rangeMin?.message,
                      data: priceOptions,
                      title: t("min_price"),
                      name: "rangeMin",
                      defaultValue: [OPTION_ANY, ...(priceOptions || [])]?.find(
                        (l) => l.id === searchParams.get("rangeMin") || ""
                      ),
                    }}
                    maxValueSelect={{
                      placeholder: t("any"),
                      error: errors.rangeMax?.message,
                      data: priceOptions,
                      title: t("max_price"),
                      name: "rangeMax",
                      defaultValue: [OPTION_ANY, ...(priceOptions || [])]?.find(
                        (l) => l.id === searchParams.get("rangeMax") || ""
                      ),
                    }}
                    title={{
                      text: "Price Range",
                      fontSize: ETypoVariant.SUB_TITLE_20,
                      className: "mb-4.5",
                    }}
                    variant="select"
                  />
                </div>
              </div>
              <div className="flex justify-end self-end pb-1 xxl:justify-center xxl:pt-4.5 lg:w-full lg:pb-0">
                <Button
                  type="submit"
                  className="border-none bg-buttonSearchBackground text-buttonSearchText lg:px-13"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.BODY_TITLE_16}
                    color={ETypoColor.BUTTON_SEARCH}
                  >
                    {t("search")}
                  </Typo>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            data-name="FilterBar-sticky"
            className={cn(
              "w-full bg-filterBarBackground shadow-filter-bar lg:bg-filterBarBackground",
              classNameSticky
            )}
          >
            <div className="container flex gap-x-6 py-4 lg:flex-col lg:px-0">
              <div className="flex flex-1 items-end justify-between gap-8 lg:flex-col lg:gap-3 [&>*:nth-child(even)]:flex-1 ">
                <div className="lg:w-full lg:flex-1 lg:px-6">
                  <SearchBar
                    inputProps={{
                      className: cn(
                        "w-full outline-none border-none text-color placeholder:text-filterBarPlaceholder text-sub-title-14 pt-0 px-0 mb-0 bg-filterBarBackground"
                      ),
                      placeholder: t("search_project_placeholder"),
                      ...register("s"),
                      value: watch("s"),
                    }}
                    enableAutocomplete
                    asyncAutocomplete
                    onSelectedChange={(option) => {
                      navigate(`/projects/${option?.projectSlug}`);
                    }}
                    renderOptionTemplate={({ option, searchString }) => (
                      <LocationOptionTemplate
                        searchString={searchString}
                        option={option}
                      />
                    )}
                    containerClass={cn(
                      "p-0 border-b border-color rounded-none pb-[8px] lg:px-0 items-start",
                      "lg:bg-searchBarBackground lg:border-none lg:px-4 lg:py-3 lg:rounded-[28px]"
                    )}
                    classNameOptions="w-max min-w-full"
                    variant="sticky"
                  />
                </div>
                <div
                  className={cn(
                    "filter-bar-sub-container w-full",
                    "lg:no-scrollbar flex flex-1 flex-row flex-nowrap gap-x-7 gap-y-3 lg:max-w-full lg:overflow-auto lg:bg-filterBarBackground lg:px-6 lg:py-3"
                  )}
                >
                  <div className="flex h-full basis-[30%] flex-col gap-3 lg:min-w-[100px] lg:max-w-[100px]">
                    <Typo
                      tag={ETypoTag.P}
                      color={ETypoColor.TEXT}
                      variant={ETypoVariant.BODY_TITLE_12}
                      className="font-bold capitalize lg:hidden"
                    >
                      {t("location")}
                    </Typo>
                    <SingleSelect
                      placeholder={t("choose_an_address")}
                      error={errors.location?.message}
                      defaultValue={[
                        OPTION_ANY,
                        ...(locationOptions || []),
                      ]?.find(
                        (l) => l.id === searchParams.get("location") || ""
                      )}
                      data={locationOptions}
                      name="location"
                      dropdownClass="w-[calc(var(--button-width)+85px)] lg:hidden"
                      containerClass="flex-1"
                      listboxButtonContainerClass="pb-[5px] items-start"
                      listboxButtonClass="pb-0 h-full block"
                      placeholderClass="leading-[1.2] line-clamp-1 truncate"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_14,
                      }}
                      textSelectedClass="text-body-title-14"
                      placeholderContainerClass="items-start h-full"
                    />
                  </div>

                  <div className="flex h-full basis-[15%] flex-col gap-3 lg:min-w-[70px] lg:max-w-[70px]">
                    <Typo
                      tag={ETypoTag.P}
                      color={ETypoColor.TEXT}
                      variant={ETypoVariant.BODY_TITLE_12}
                      className="font-bold capitalize lg:hidden"
                    >
                      {t("type")}
                    </Typo>
                    <SingleSelect
                      placeholder={t("any")}
                      error={errors.type?.message}
                      name="type"
                      title=""
                      data={convertUnitTypeOptions(unitTypeOptions)}
                      dropdownClass="lg:hidden"
                      defaultValue={[
                        OPTION_ANY,
                        ...convertUnitTypeOptions(unitTypeOptions || []),
                      ]?.find((l) => l.id === searchParams.get("type") || "")}
                      containerClass="flex-1"
                      listboxButtonContainerClass="pb-[5px] items-start"
                      listboxButtonClass="pb-0 h-full block"
                      placeholderClass="leading-[1.2]"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_14,
                      }}
                      textSelectedClass="text-body-title-14"
                      placeholderContainerClass="items-start h-full"
                    />
                  </div>

                  <div className="flex h-full basis-[15%] flex-col gap-3 lg:min-w-[70px] lg:max-w-[70px]">
                    <Typo
                      tag={ETypoTag.P}
                      color={ETypoColor.TEXT}
                      variant={ETypoVariant.BODY_TITLE_12}
                      className="font-bold capitalize lg:hidden"
                    >
                      {t("tenure")}
                    </Typo>
                    <SingleSelect
                      placeholder={t("any")}
                      error={errors.tenure?.message}
                      title=""
                      name="tenure"
                      dropdownClass="lg:hidden"
                      data={OPTIONS_TENURE}
                      defaultValue={[OPTION_ANY, ...OPTIONS_TENURE]?.find(
                        (l) => l.id === searchParams.get("tenure") || ""
                      )}
                      containerClass="flex-1"
                      listboxButtonContainerClass="pb-[5px] items-start"
                      listboxButtonClass="pb-0 h-full block"
                      placeholderClass="leading-[1.2]"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_14,
                      }}
                      textSelectedClass="text-body-title-14"
                      placeholderContainerClass="items-start h-full"
                    />
                  </div>

                  <div className="flex h-full basis-[15%] flex-col gap-3 lg:min-w-[70px] lg:max-w-[70px]">
                    <Typo
                      tag={ETypoTag.P}
                      color={ETypoColor.TEXT}
                      variant={ETypoVariant.BODY_TITLE_12}
                      className="font-bold capitalize lg:hidden"
                    >
                      {t("TOP")}
                    </Typo>
                    <SingleSelect
                      placeholder={t("any")}
                      error={errors.top?.message}
                      data={topOptions}
                      title=""
                      name="top"
                      defaultValue={[OPTION_ANY, ...(topOptions || [])]?.find(
                        (l) => l.id === searchParams.get("top") || ""
                      )}
                      dropdownClass="lg:hidden"
                      containerClass="flex-1"
                      listboxButtonContainerClass="pb-[5px] items-start"
                      listboxButtonClass="pb-0 h-full block"
                      placeholderClass="leading-[1.2]"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_14,
                      }}
                      textSelectedClass="text-body-title-14"
                      placeholderContainerClass="items-start h-full"
                    />
                  </div>

                  <div className="flex h-full basis-[30%] flex-col gap-3 lg:min-w-[100px] lg:max-w-[100px]">
                    <Typo
                      tag={ETypoTag.P}
                      color={ETypoColor.TEXT}
                      variant={ETypoVariant.BODY_TITLE_12}
                      className="font-bold capitalize lg:hidden lg:min-w-12"
                    >
                      {t("price_range")}
                    </Typo>
                    <InputRange
                      errors={{
                        maxErrors: errors.rangeMax?.message,
                        minErrors: errors.rangeMin?.message,
                      }}
                      minValueSelect={{
                        placeholder: t("any"),
                        error: errors.rangeMin?.message,
                        data: priceOptions,
                        title: t("min_price"),
                        name: "rangeMin",
                        defaultValue: [
                          OPTION_ANY,
                          ...(priceOptions || []),
                        ]?.find(
                          (l) => l.id === searchParams.get("rangeMin") || ""
                        ),
                        dropdownClass: "lg:hidden",
                        containerClass: "flex-1",
                        listboxButtonContainerClass: "pb-[5px] items-start",
                        listboxButtonClass: "pb-0 h-full block",
                        placeholderClass: "leading-[1.2]",
                        placeholderProps: {
                          variant: ETypoVariant.BODY_TITLE_14,
                        },
                        textSelectedClass: "text-body-title-14",
                        placeholderContainerClass: "items-start h-full",
                      }}
                      maxValueSelect={{
                        placeholder: t("any"),
                        error: errors.rangeMax?.message,
                        data: priceOptions,
                        title: t("max_price"),
                        name: "rangeMax",
                        defaultValue: [
                          OPTION_ANY,
                          ...(priceOptions || []),
                        ]?.find(
                          (l) => l.id === searchParams.get("rangeMax") || ""
                        ),
                        dropdownClass: "lg:hidden",
                        containerClass: "flex-1",
                        listboxButtonContainerClass: "pb-[5px] items-start",
                        listboxButtonClass: "pb-0 h-full block",
                        placeholderClass: "leading-[1.2]",
                        placeholderProps: {
                          variant: ETypoVariant.BODY_TITLE_14,
                        },
                        textSelectedClass: "text-body-title-14",
                        placeholderContainerClass: "items-start h-full",
                      }}
                      title={{
                        text: "",
                        fontSize: ETypoVariant.SUB_TITLE_14,
                        className: "",
                      }}
                      variant="select"
                      customclassInput="text-sub-title-14"
                      unitClass="text-sub-title-14"
                      wrapperClass="items-stretch"
                      dividerClass="mt-0"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end self-end xxl:justify-center xxl:pt-4.5 lg:hidden lg:w-full lg:pb-0">
                <Button
                  type="submit"
                  className="border-none bg-buttonSearchBackground lg:px-13"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.BODY_TITLE_16}
                    color={ETypoColor.BUTTON_SEARCH}
                  >
                    {t("search")}
                  </Typo>
                </Button>
              </div>
            </div>
          </div>
        )
      }
    </Form>
  );
};

export default FilterBar;
