import * as zod from "zod";

export const filterBarSchema = zod.object({
  s: zod.coerce.string(),
  location: zod.coerce.string(),
  type: zod.coerce.string(),
  tenure: zod.coerce.string(),
  rangeMin: zod.coerce.number().or(zod.coerce.string()),
  rangeMax: zod.coerce.number().or(zod.coerce.string()),
  top: zod.coerce.string(),
  page: zod.coerce.number().optional(),
  limit: zod.coerce.number().optional(),
});

export type TFilterBarFormData = zod.infer<typeof filterBarSchema>;
