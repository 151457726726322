import { InputRangeProps } from "./type";
import { Input } from "../Input";
import { SingleSelect } from "../SelectBox";
import Typo from "../Typo";

import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

const InputRange: React.FC<InputRangeProps> = (props) => {
  const {
    errors,
    customWrapInputClass = "",
    unitClass = "text-sub-title-16",
    customclassInput = "",
    wrapperClass = "",
    dividerClass = "",
    variant = "input",
    minValueSelect,
    maxValueSelect,
  } = props;
  const {
    text,
    tag = "p",
    fontSize = ETypoVariant.SUB_TITLE_16,
    className = "mb-3",
  } = props.title;
  const classWrapInput = props.unit
    ? "flex items-end border-b border-color w-full pb-[19px] lg:pb-3"
    : "";
  const classWrapSelect = props.unit ? "flex items-end w-full" : "";
  const classInput = props.unit
    ? "w-full outline-none border-none text-color text-sub-title-16 pt-0 px-2 -mb-1.5"
    : "w-full";

  if (variant === "select") {
    return (
      <div className="w-full">
        <Typo
          tag={tag}
          color="text-color"
          variant={fontSize}
          className={cn("font-bold lg:text-sub-title-16", className)}
        >
          {text}
        </Typo>
        <div className={cn("flex w-full items-start gap-2", wrapperClass)}>
          <div className="flex w-1/2 flex-col gap-y-2">
            <div className={cn(classWrapSelect, customWrapInputClass)}>
              <SingleSelect
                {...minValueSelect}
                placeholder={minValueSelect?.placeholder}
                error={minValueSelect?.error}
                data={minValueSelect?.data || []}
                title=""
                name={minValueSelect?.name || ""}
                defaultValue={minValueSelect?.defaultValue}
                containerClass={cn(
                  "w-full",
                  maxValueSelect?.containerClass || ""
                )}
              />
            </div>
            {errors?.minErrors && (
              <Typo
                tag={ETypoTag.P}
                variant={ETypoVariant.BODY_TITLE_14}
                color={ETypoColor.BRICK_RED}
              >
                {errors.minErrors}
              </Typo>
            )}
          </div>

          <div className="flex w-1/2 flex-col gap-y-2">
            <div className={cn(classWrapSelect, customWrapInputClass)}>
              <SingleSelect
                {...maxValueSelect}
                placeholder={maxValueSelect?.placeholder}
                error={maxValueSelect?.error}
                data={maxValueSelect?.data || []}
                title=""
                name={maxValueSelect?.name || ""}
                defaultValue={maxValueSelect?.defaultValue}
                containerClass={cn(
                  "w-full",
                  maxValueSelect?.containerClass || ""
                )}
              />
            </div>
            {errors?.maxErrors && (
              <Typo
                tag={ETypoTag.P}
                variant={ETypoVariant.BODY_TITLE_14}
                color={ETypoColor.ERROR}
              >
                {errors.maxErrors}
              </Typo>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <Typo
        tag={tag}
        color="text-color"
        variant={fontSize}
        className={cn("font-bold lg:text-sub-title-16", className)}
      >
        {text}
      </Typo>
      <div className={cn("flex w-full items-start gap-2", wrapperClass)}>
        <div className="flex flex-col gap-y-2 xl:w-1/2">
          <div className={cn(classWrapInput, customWrapInputClass)}>
            {props.unit && (
              <span
                className={cn(
                  "translate-y-0.5 text-sub-title-16 text-color",
                  unitClass
                )}
              >
                {props.unit}
              </span>
            )}
            <Input
              {...props.minValue}
              placeholder={props?.minValue?.placeholder || ""}
              autoComplete="off"
              className={cn(classInput, customclassInput)}
            />
          </div>
          {errors?.minErrors && (
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.BODY_TITLE_14}
              color={ETypoColor.ERROR}
            >
              {errors.minErrors}
            </Typo>
          )}
        </div>

        <div
          className={cn(
            "h-full text-center text-color lg:min-w-2",
            props.unit ? "-mt-1 lg:mt-0" : "",
            dividerClass
          )}
        >
          {" "}
          -{" "}
        </div>
        <div className="flex flex-col gap-y-2 xl:w-1/2">
          <div className={cn(classWrapInput, customWrapInputClass)}>
            {props.unit && (
              <span
                className={cn(
                  "translate-y-0.5 text-sub-title-16 text-color",
                  unitClass
                )}
              >
                {props.unit}
              </span>
            )}
            <Input
              {...props.maxValue}
              placeholder={props?.maxValue?.placeholder || ""}
              autoComplete="off"
              className={cn(classInput, customclassInput)}
            />
          </div>
          {errors?.maxErrors && (
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.BODY_TITLE_14}
              color={ETypoColor.ERROR}
            >
              {errors.maxErrors}
            </Typo>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputRange;
